import { Header, Table } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { PlusIcon, LeftArrowIcon, RightArrowIcon, EditIcon, DeleteIcon, XIcon, SuccessIcon } from "../components/Icons";
import { useProducts } from "../api/product";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";
import { MEDIA_URL } from "../urls";
export const LinkButton = ({ children }) => {
  const { currentLang } = useStateContext();
  return (
    <Link
      to="create/"
      className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
    >
      <PlusIcon />
      <span className="font-medium  mx-2 dark:text-white">{children}</span>
      {currentLang == "ar" ? <LeftArrowIcon /> : <RightArrowIcon />}
    </Link>
  );
};


const Products = () => {
  const { products, deleteProduct } = useProducts();
  const { currentLang } = useStateContext();
  const columns = {
    title: {
      title: translator.tables.products.products[currentLang],
      render: (row) => (
        <Link
          to={`/${row.id}`}
          title="preview"
          className="flex items-center px-2 hover:text-blue-600 hover:underline"
        >
          <img
            className="w-10 h-10 mx-2 rounded-full"
            src={MEDIA_URL + row.image}
          />
          {row.title}
        </Link>
      ),
    },
    buy_price: {
      title: translator.tables.products.buyPrice[currentLang],
      render: (row) => row?.buy_price?.toFixed(2),
    },
    price: {
      title: translator.tables.products.price[currentLang],
      render: (row) => row.price.toFixed(2),
    },
    act_price: {
      title: translator.tables.products.actPrice[currentLang],
      render: (row) =>
        row?.act_price.toFixed(2) === row.price.toFixed(2) ? (
          <p className="font-bold text-lg">---</p>
        ) : (
          row.act_price.toFixed(2)
        ),
    },
    in_stock: {
      title: translator.tables.products.inStock[currentLang],
    },
    orders: {
      title: translator.tables.products.orders[currentLang],
    },
    earning: {
      title: translator.tables.products.earning[currentLang],
      render: (row) => row.earning?.toFixed(2),
    },
    reviews: {
      title: translator.tables.products.reviews[currentLang],
    },
    reviews_avg: {
      title: translator.tables.products.avgReviews[currentLang],
    },
  };
  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/products/edit/${id}`);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <header className="flex justify-between items-center">
        <Header
          category={translator.shared.paper[currentLang]}
          title={translator.pages.products.name[currentLang]}
        />
        <div className="flex gap-x-6">
          <LinkButton>
            {translator.pages.products.linkBtn[currentLang]}
          </LinkButton>
        </div>
      </header>
      <Table
        columns={columns}
        data={products}
        options
        handleDelete={deleteProduct}
        handleEdit={handleEdit}
      >
        <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
          <p className="font-bold text-gray-600">
            {translator.tables.products.null[currentLang]}
          </p>
        </div>
      </Table>
    </div>
  );
};
export default Products;
