import { useState } from "react";
import { Header, Table, PopUp } from "../components";
import { PlusIcon } from "../components/Icons";
import { Input, SelectInput } from "../components/Input";
import { ActionButton } from "../components/Button";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";
import { useGetCategories } from "../api/category";
import { flattenCategories } from "../utils";

export const LinkButton = ({ children, onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
    >
      <PlusIcon />
      <span className="font-medium ml-1 mr-2 dark:text-white">{children}</span>
    </button>
  );
};

const CategoryForm = ({ setOpen, onSubmit, data, action, categories }) => {
  const { handleNotification, currentLang } = useStateContext();
  const [form, setForm] = useState({
    id: data?.id,
    name: data?.name,
    parentID: data?.parent,
  });

  const handleSubmit = () => {
    if (!form.name) {
      handleNotification({ type: "error", message: "Complete missing data" });
      return;
    }
    onSubmit(form);
    setOpen();
  };
  return (
    <PopUp
      title={`${action} ${translator.forms.categories.name[currentLang]}`}
      onClose={() => setOpen()}
    >
      <div className="px-6">
        <SelectInput
          item={"name"}
          values={categories}
          value={data?.parent}
          onChange={(e) => setForm({ ...form, parentID: e.target.value })}
          label={translator.forms.categories.parent[currentLang]}
          className="w-[250px] mb-4 mt-4"
        />
        <Input
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          label={translator.forms.categories.name[currentLang]}
          type="text"
          className="w-[250px] mb-4"
        />
      </div>
      <div className="w-full">
        <ActionButton
          onClick={() => handleSubmit()}
          className="float-right mt-4 mr-4 px-8"
        >
          {action}
        </ActionButton>
      </div>
    </PopUp>
  );
};

function Categories() {
  const [open, setOpen] = useState();
  const { currentLang } = useStateContext();
  const { categories: categoriesTree, createCategory, updateCategory, deleteCategory } =
    useGetCategories();
  const categories = flattenCategories(categoriesTree)
  const columns = {
    name: {
      title: translator.tables.categories.name[currentLang],
    },
    parent: {
      title: translator.tables.categories.parent[currentLang],
      render: ({ parent }) => parent ? `1 ${translator.tables.categories.parent[currentLang]}` : `2 ${translator.tables.categories.parent[currentLang]}`
    },
  };
  const Form = (props) => <CategoryForm {...props} categories={categories} />
  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <header className="flex justify-between items-center">
        <Header
          category={translator.shared.paper[currentLang]}
          title={translator.pages.categories.name[currentLang]}
        />
        <LinkButton onClick={() => setOpen(true)} to="create/">
          {translator.pages.categories.linkBtn[currentLang]}
        </LinkButton>
      </header>
      {open && (
        <CategoryForm
          action={translator.shared.add[currentLang]}
          data={{
            name: "",
            parentID: "",
          }}
          onSubmit={(data) => createCategory(data)}
          setOpen={() => setOpen(false)}
          categories={categories}
        />
      )}
      <Table
        columns={columns}
        data={categories}
        handleEdit={updateCategory}
        handleDelete={deleteCategory}
        options
        Form={Form}
      >
        <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
          <p className="font-bold text-gray-600">
            {translator.tables.categories.null[currentLang]}
          </p>
        </div>
      </Table>
    </div>
  );
}
export default Categories;
