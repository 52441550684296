import { useState } from "react";
import { Header, Table, PopUp } from "../components";
import { PlusIcon } from "../components/Icons";
import { Input, SelectInput } from "../components/Input";
import { ActionButton } from "../components/Button";
import { useDiscounts } from "../api/discount";
import { useProducts } from "../api/product";
import { formattedDate, renderDate } from "../utils";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";
import { MEDIA_URL } from "../urls";
import { translator } from "../translation";

export const LinkButton = ({ children, onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
    >
      <PlusIcon />
      <span className="font-medium ml-1 mr-2 dark:text-white">{children}</span>
    </button>
  );
};

const DiscountForm = ({ setOpen, onSubmit, data, action }) => {
  const { handleNotification, currentLang } = useStateContext();
  const { products } = useProducts();
  const [form, setForm] = useState({
    id: data.id,
    product_id: data.product__id,
    new_price: data.new_price,
    title: data.title,
    end_date: data.end_date ? formattedDate(data.end_date) : "",
  });
  const handleSubmit = () => {
    if (!form.product_id || !form.new_price || !form.title || !form.end_date) {
      handleNotification({ type: "error", message: "Complete missing data" });
      return;
    }
    onSubmit(form);
    setOpen();
  };
  return (
    <PopUp
      title={`${action} ${translator.forms.discounts.title[currentLang]}`}
      onClose={() => setOpen()}
    >
      <div className="px-6">
        <SelectInput
          item={"title"}
          values={products}
          value={form.product_id}
          onChange={(e) => setForm({ ...form, product_id: e.target.value })}
          label={translator.forms.shared.product[currentLang]}
          className="w-[250px] mb-4 mt-4"
        />
        <Input
          value={form.new_price}
          onChange={(e) => setForm({ ...form, new_price: e.target.value })}
          label={translator.forms.discounts.new_price[currentLang]}
          type="number"
          className="w-[250px] mb-4"
        />
        <Input
          value={form.end_date}
          onChange={(e) => setForm({ ...form, end_date: e.target.value })}
          label={translator.forms.discounts.endDate[currentLang]}
          type="date"
          className="w-[250px] mb-4"
        />
      </div>
      <div className="w-full">
        <ActionButton
          onClick={() => handleSubmit()}
          className="float-right mt-4 mr-4 px-8"
        >
          {action}
        </ActionButton>
      </div>
    </PopUp>
  );
};

function Discounts() {
  const [open, setOpen] = useState();
  const { currentLang } = useStateContext();
  const { discounts, createDiscount, updateDiscount, deleteDiscount } =
    useDiscounts();
  const columns = {
    title: {
      title: translator.tables.discounts.title[currentLang],
    },
    new_price: {
      title: translator.tables.discounts.new_price[currentLang],
      render: (row) => <p>{row.new_price.toFixed(2)} %</p>,
    },
    end_date: {
      title: translator.tables.discounts.endDate[currentLang],
      render: (row) => renderDate(row.end_date),
    },
    product__id: {
      title: translator.tables.discounts.product[currentLang],
      render: (row) => (
        <Link
          title="preview"
          className="flex items-center justify-center px-2 hover:text-blue-600 hover:underline"
        >
          <img
            className="rounded-full w-10 h-10 mx-2 "
            src={MEDIA_URL + row.product_image}
          />

          {row.product__title}
        </Link>
      ),
    },
    orders: {
      title: translator.tables.discounts.orders[currentLang],
    },
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <header className="flex justify-between items-center">
        <Header
          category={translator.shared.paper[currentLang]}
          title={translator.pages.discounts.name[currentLang]}
        />
        <LinkButton onClick={() => setOpen(true)} to="create/">
          {translator.pages.discounts.linkBtn[currentLang]}
        </LinkButton>
      </header>
      {open && (
        <DiscountForm
          action={translator.shared.add[currentLang]}
          data={{
            title: "",
            new_price: "",
            end_date: "",
            product__id: "",
          }}
          onSubmit={(data) => createDiscount(data)}
          setOpen={() => setOpen(false)}
        />
      )}
      <Table
        columns={columns}
        data={discounts}
        handleEdit={updateDiscount}
        handleDelete={deleteDiscount}
        options
        Form={DiscountForm}
      >
        <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
          <p className="font-bold text-gray-600">
            {translator.tables.discounts.null[currentLang]}
          </p>
        </div>
      </Table>
    </div>
  );
}
export default Discounts;
