const URL = process.env.REACT_APP_API_URL

export const useGET = async (url, conf = {}) => {
  const get = {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
  };
  const results = await fetch(`${URL}${url}`, get)
    .then((response) => {
      if (response.status === 403) {
        window.location.href = '/login';
      }
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};

export const usePOST = async (url, conf) => {
  const post = {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(conf?.data),
  };
  if (conf?.form) {
    delete post.headers["Content-Type"];
    post.body = conf.form;
  }
  const results = await fetch(`${URL}${url}`, post)
    .then((response) => {
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};

export const useUPDATE = async (url, conf) => {
  const PUT = {
    method: "PUT",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(conf?.data),
  };
  if (conf?.form) {
    delete PUT.headers["Content-Type"];
    PUT.body = conf.form;
  }

  const results = await fetch(`${URL}${url}`, PUT)
    .then((response) => {
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};

export const useDELETE = async (url, conf) => {
  const delete_ = {
    method: "DELETE",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(conf?.data),
  };
  const results = await fetch(`${URL}${url}`, delete_)
    .then((response) => {
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};
