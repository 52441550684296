import { useEffect, useState } from "react";
import { useGET, usePOST, useUPDATE, useDELETE } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

export const useProducts = (id = false) => {
  const [products, setProducts] = useState([]);
  const [form, setForm] = useState({
    images: [],
    title: "",
    category: "",
    quantity: "",
    price: "",
    buy_price: "",
    description: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleNotification } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    useGET(`v2/products/${id ? `${id}/` : ""}`, {}).then((res) => {
      if (!id) setProducts(res);
      if (id) {
        setForm({
          id: res.id,
          images: res.images,
          title: res.title,
          quantity: res.in_stock,
          category: res.category,
          price: res.price,
          buy_price: res.buy_price,
          description: res.description,
          variants: res.variants,
        });
      }
      if (res.type == "error") handleNotification({ type: 'error', message: 'Something went wrong try later' });
    });
  }, [refresh]);

  const handleData = (data) => {
    const formData = new FormData();
    const images = data.images.filter((image) => image.id != undefined);
    const newImages = data.images.filter((image) => image.id == undefined);
    data.images = images.map((image) => image.id);


    newImages.map((image, i) => {
      formData.append(`image[${i}]`, image.file);
    });
    formData.append("data", JSON.stringify(data));
    return formData;
  };

  const createProduct = (data) => {
    setLoading(true);
    const form = handleData(data);
    usePOST("vendor/products/", {
      form: form,
    }).then((res) => {
      handleNotification(res);
      if (res.type == "success") {
        navigate("/products");
      }
      setLoading(false);
    });
  };
  const updateProduct = (data) => {
    const updatedForm = handleData(data);
    useUPDATE("vendor/products/", {
      form: updatedForm,
    }).then((res) => {
      handleNotification(res);
    });
  };

  const deleteProduct = (id) => {
    setLoading(true);
    useDELETE("vendor/products", {
      data: { id: id },
    }).then((res) => {
      handleNotification(res);
      setLoading(false);
      if (res.type == "success") {
        setRefresh(!refresh);
      }
    });
  };
  return {
    products,
    deleteProduct,
    createProduct,
    updateProduct,
    form,
    setForm,
  };
};

export const useVariants = () => {
  const [variants, setvariants] = useState([]);
  const { handleNotification } = useStateContext();
  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    useGET(`v2/attributes/`, {}).then(
      (res) => {
        setvariants(res);
      }
    );
  }, [refresh]);

  const createVariant = (data) => {
    setLoading(true);
    usePOST("v2/attributes/", {
      data: data,
    }).then((res) => {
      setLoading(false);
      if (res?.id) {
        handleNotification({ type: 'success', message: 'variant created' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong try later' });

      }
    });
  };
  const updateVariant = (data) => {
    setLoading(true);
    useUPDATE(`v2/attribute/${data.id}`, {
      data: data,
    }).then((res) => {
      setLoading(false);
      if (res?.id) {
        handleNotification({ type: 'success', message: 'variant updated' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong try later' });
      }
    });
  };

  const deleteVariant = (id) => {
    setLoading(true);
    useDELETE(`v2/attribute/${id}`, {
      data: { id: id },
    }).then((res) => {
      setLoading(false);
      if (res?.type === 'error') {
        handleNotification({ type: 'success', message: 'variant deleted' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong try later' });
      }
    });
  };

  return { variants, createVariant, updateVariant, deleteVariant };
};

export const useVariantValues = (id) => {
  const [variantValues, setvariantValues] = useState([]);
  const { handleNotification } = useStateContext();
  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    useGET(`v2/attribute-values/?attrId=${id}`, {}).then(
      (res) => {
        setvariantValues(res);
      }
    );
  }, [refresh]);

  const createVariantValue = (data) => {
    setLoading(true);
    usePOST('v2/attribute-values/', {
      data: data,
    }).then((res) => {
      setLoading(false);
      if (res?.id) {
        handleNotification({ type: 'success', message: 'variant value created' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong try later' });

      }
    });
  };
  const updateVariantValue = (data) => {
    setLoading(true);
    useUPDATE(`v2/attribute-values/${data.id}/`, {
      data: data,
    }).then((res) => {
      setLoading(false);
      if (res?.id) {
        handleNotification({ type: 'success', message: 'variant value updated' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong try later' });
      }
    });
  };

  const deleteVariantValue = (id) => {
    setLoading(true);
    useDELETE(`v2/attribute-values/${id}/`, {
      data: { id: id },
    }).then((res) => {
      setLoading(false);
      if (res.type === "error") {
        handleNotification({ type: 'success', message: 'variant value deleted' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong try later' });
      }
    });
  };

  return { variantValues, createVariantValue, updateVariantValue, deleteVariantValue };
};

