import { Header, PopUp } from "../components";
import { ActionButton } from "../components/Button";
import { Input, SelectInput, DragAndDrop } from "../components/Input";
import ReactMarkdown from "react-markdown";
import { Eye, EyeSlash, MinusIcon } from "../components/Icons";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useProducts, useVariants } from "../api/product";
import { useGetCategories } from "../api/category";
import { usePackaging } from "../api/packaging";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";
import { flattenCategories } from "../utils";

export const LinkButton = ({ children, to, onClick = () => { } }) => {
  return (
    <Link
      onClick={onClick}
      to={to}
      className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
    >
      <span className="font-medium ml-1 mr-2 dark:text-white">{children}</span>
    </Link>
  );
};

const PackagingForm = ({ action, close, data }) => {
  const { handleNotification, currentLang } = useStateContext();
  const { variants } = useVariants()
  const { togglePackaging } = usePackaging()
  const [form, setForm] = useState({
    id: data?.id,
    product_id: data?.id,
    attribute_ids: data?.variants?.map(({ id }) => id),
  });
  const handleSubmit = () => {
    if (!form.product_id || !form.new_price || !form.title || !form.end_date) {
      handleNotification({ type: "error", message: "Complete missing data" });
      return;
    }
    togglePackaging(form);
    close();
  };
  const handleOnChange = (id) => {
    if (form.attribute_ids.includes(id)) {
      setForm({
        ...form,
        attribute_ids: form.attribute_ids.filter(
          (attrId) => id != attrId)
      })
    } else {
      setForm({
        ...form,
        attribute_ids: [...form.attribute_ids, id]
      })

    }
    console.log(form.attribute_ids)
  }
  return (
    <PopUp
      title={`${action} ${translator.forms.shared.package[currentLang]}`}
      onClose={() => close()}
    >
      <div className="px-6 w-full flex flex-col gap-4 min-w-[300px]">
        {variants.map(({ id, name, values }) =>
          <details id={id}>
            <summary className="font-bold text-lg">{name}</summary>
            <ul className="my-2">
              {values.map(({ id, value, attribute }) => {
                return (
                  <li id={id} className="flex hover:bg-gray-200 rounded-md cursor-pointer px-2 py-1 items-center justify-start gap-2">
                    <input checked={form.attribute_ids.includes(id)} onChange={() => handleOnChange(id)} type="checkbox" name={id} id={id} />
                    <label className="cursor-pointer" htmlFor={id}>{value}</label>
                  </li>
                )
              })}
            </ul>
          </details>
        )}
      </div>
      <div className="w-full">
        <ActionButton
          onClick={() => handleSubmit()}
          className="float-right mt-4 mr-4 px-8"
        >
          {action}
        </ActionButton>
      </div>
    </PopUp>
  );
};

function CreateProduct() {
  const { id } = useParams();
  const { createProduct, form, setForm, updateProduct } = useProducts(id);
  const { handleNotification, currentLang } = useStateContext();
  const { categories } = useGetCategories();
  const [preview, setPerview] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    if (
      !form.images ||
      !form.title ||
      !form.category ||
      !form.price ||
      !form.description
    ) {
      handleNotification({ type: "error", message: "complete missing data" });
      return;
    }
    if (!id) createProduct(form);
    if (id) updateProduct(form);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      {open && <PackagingForm data={form} action={translator.shared.add[currentLang]} onSubmit={() => { }} close={() => setOpen(false)} />}

      <header className="flex justify-between items-center">
        <Header
          category={translator.shared.paper[currentLang]}
          title={translator.forms.product.title[currentLang]}
        />
        <div className="flex gap-4">
          {id && <LinkButton onClick={() => setOpen(true)} to="#">{translator.forms.shared.package[currentLang]}</LinkButton>}
          <LinkButton to="/products/">{translator.forms.product.back[currentLang]}</LinkButton>
        </div>
      </header>
      <form className="flex flex-wrap gap-4 p-4 items-center justify-center">
        <div className="pt-6 flex">
          <div className="flex flex-col gap-2">
            {form.images?.map((image) => (
              <div
                style={{
                  backgroundSize: "cover",
                  backgroundImage: `url(${image.image})`,
                }}
                className="w-16 h-16 mr-2 border border-gray-300 rounded-md"
              >
                <MinusIcon
                  onClick={() =>
                    setForm({
                      ...form,
                      images: form.images.filter((i) => i != image),
                    })
                  }
                />
              </div>
            ))}
          </div>
          <DragAndDrop
            id="product-image"
            onChange={(file) => {
              if (form.images.length < 4)
                setForm({ ...form, images: [...form.images, file] });
              else
                handleNotification({
                  type: "error",
                  message: translator.forms.product.imageError[currentLang],
                });
            }}
            label={translator.forms.product.image[currentLang]}
            className="h-[310px] rounded-lg ml-2 w-56 max-sm:w-[300px]"
          />
        </div>

        <div>
          <Input
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            label={translator.forms.product.name[currentLang]}
            type="text"
            className="w-[500px] mb-5"
          />
          <SelectInput
            values={flattenCategories(categories)}
            value={form.category}
            item="name"
            onChange={(e) => setForm({ ...form, category: e.target.value })}
            className="w-full"
            label={translator.forms.product.category[currentLang]}
          />
          <div className="flex mt-4 justify-between mb-5">
            <div className="mr-4">
              <Input
                value={form.price}
                onChange={(e) => setForm({ ...form, price: e.target.value })}
                label={translator.forms.product.sellPrice[currentLang]}
                type="number"
                className="w-[140px]"
              />
            </div>
            <Input
              value={form.buy_price}
              onChange={(e) => setForm({ ...form, buy_price: e.target.value })}
              label={translator.forms.product.buyPrice[currentLang]}
              type="number"
              className="w-[140px]"
            />
          </div>
          <div className="flex mt-4 justify-between mb-5">
            <Input
              value={form.quantity}
              onChange={(e) => setForm({ ...form, quantity: e.target.value })}
              label={translator.forms.product.quantity[currentLang]}
              type="number"
              className="w-[120px]"
            />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label
              className="block dark:text-white text-md text-gray-700 font-bold mb-2"
              htmlFor="editor"
            >
              {translator.forms.product.description[currentLang]}
            </label>
            <div
              onClick={() => setPerview(!preview)}
              className="p-2 border rounded-md cursor-pointer bg-gray-50 mb-2"
            >
              {!preview ? <Eye /> : <EyeSlash />}
            </div>
          </div>
          {!preview && (
            <textarea
              className="overflow-y-auto focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 appearance-none dark:text-white bg-transparent border rounded w-full py-2 px-3 text-gray-700 leading-tight min-w-[355px] max-w-[355px] min-h-[250px] max-h-[250px]"
              value={form.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
          )}
          {preview && (
            <ReactMarkdown
              className="overflow-y-auto focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 appearance-none dark:text-white bg-transparent border rounded w-full py-2 px-3 text-gray-700 leading-tight min-w-[355px] max-w-[355px] min-h-[250px] max-h-[250px]"
              children={form.description}
            />
          )}
        </div>
      </form>
      <div className="w-full flex items-center justify-center">

        <ActionButton
          onClick={() => handleSubmit()}
          className="self-end mt-6 w-64"
        >
          {translator.forms.product.title[currentLang]}
        </ActionButton>
      </div>
    </div>
  );
}

export default CreateProduct;
