import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { Navbar, ToolTip, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Dashboard,
  Orders,
  Products,
  CreateProduct,
  Coupons,
  Discounts,
  Settings,
  Customers,
  Visitors,
  Categories,
  Login,
  Logout,
  VariantValues,
  Variants
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";

const App = () => {
  const { setCurrentMode, user, currentMode, currentLang } = useStateContext();

  useEffect(() => {
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeMode) {
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div
      lang={currentLang}
      dir={currentLang == "ar" ? "rtl" : "ltr"}
      className={currentMode === "Dark" ? "dark" : ""}
    >
      <Routes>
        {/* dashboard  */}
        <Route
          path="/"
          element={
            <ProtectedLayout>
              <Dashboard />
            </ProtectedLayout>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedLayout>
              <Dashboard />
            </ProtectedLayout>
          }
        />
        {/* pages  */}
        <Route
          path="/orders/"
          element={
            <ProtectedLayout>
              <Orders />
            </ProtectedLayout>
          }
        />
        <Route
          path="/categories/"
          element={
            <ProtectedLayout>
              <Categories />
            </ProtectedLayout>
          }
        />
        <Route
          path="/products/"
          element={
            <ProtectedLayout>
              <Products />
            </ProtectedLayout>
          }
        />
        <Route
          path="/products/create/"
          element={
            <ProtectedLayout>
              <CreateProduct />
            </ProtectedLayout>
          }
        />
        <Route
          path="/products/edit/:id"
          element={
            <ProtectedLayout>
              <CreateProduct />
            </ProtectedLayout>
          }
        />
        <Route
          path="/customers/"
          element={
            <ProtectedLayout>
              <Customers />
            </ProtectedLayout>
          }
        />
        <Route
          path="/coupons/"
          element={
            <ProtectedLayout>
              <Coupons />
            </ProtectedLayout>
          }
        />
        <Route
          path="/variants/"
          element={
            <ProtectedLayout>
              <Variants />
            </ProtectedLayout>
          }
        />
        <Route
          path="/variant-values/:id"
          element={
            <ProtectedLayout>
              <VariantValues />
            </ProtectedLayout>
          }
        />
        <Route
          path="/discounts/"
          element={
            <ProtectedLayout>
              <Discounts />
            </ProtectedLayout>
          }
        />
        <Route
          path="/visitors/"
          element={
            <ProtectedLayout>
              <Visitors />
            </ProtectedLayout>
          }
        />
        <Route
          path="/settings/"
          element={
            <ProtectedLayout>
              <Settings />
            </ProtectedLayout>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </div>
  );
};

export default App;

const ProtectedLayout = ({ children }) => {
  const {
    setCurrentColor,
    activeMenu,
    currentColor,
    themeSettings,
    currentLang,
    setThemeSettings,
  } = useStateContext();
  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    if (currentThemeColor) {
      setCurrentColor(currentThemeColor);
    }
  }, []);
  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <div
        className={`fixed ${currentLang == "rtl" ? "left-4" : "right-4"
          } bottom-9 z-20`}
      >
        <ToolTip title="Settings">
          <button
            type="button"
            onClick={() => setThemeSettings(true)}
            style={{ background: currentColor, borderRadius: "50%" }}
            className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <FiSettings />
          </button>
        </ToolTip>
      </div>
      {activeMenu ? (
        <div className="w-72 z-10 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <Sidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? `dark:bg-main-dark-bg  bg-main-bg min-h-screen ${currentLang == "ar" ? "md:mr-72" : "md:ml-72"
            } w-full overflow-hidden`
            : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
        }
      >
        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <Navbar />
        </div>
        <div className="overflow-hidden">
          {themeSettings && <ThemeSettings />}
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};
