import { useState } from "react";
import { Header, Table, PopUp } from "../components";
import { PlusIcon } from "../components/Icons";
import { Input } from "../components/Input";
import { ActionButton } from "../components/Button";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";
import { useVariants } from "../api/product";
import { Link } from "react-router-dom";

export const LinkButton = ({ children, onClick }) => {
    return (
        <button
            onClick={() => onClick()}
            className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
        >
            <PlusIcon />
            <span className="font-medium ml-1 mr-2 dark:text-white">{children}</span>
        </button>
    );
};


const VariantForm = ({ setOpen, onSubmit, data, action }) => {
    const { handleNotification, currentLang } = useStateContext();
    const [form, setForm] = useState({
        id: data?.id,
        name: data?.name,
        parentID: data?.parent_id,
    });
    const handleSubmit = () => {
        if (!form.name) {
            handleNotification({ type: "error", message: "Complete missing data" });
            return;
        }
        onSubmit(form);
        setOpen();
    };
    return (
        <PopUp
            title={`${action} ${translator.pages.variants.linkBtn[currentLang]}`}
            onClose={() => setOpen()}
        >
            <div className="px-6">
                <Input
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    label={translator.forms.categories.name[currentLang]}
                    type="text"
                    className="w-[250px] mb-4"
                />
            </div>
            <div className="w-full">
                <ActionButton
                    onClick={() => handleSubmit()}
                    className="float-right mt-4 mr-4 px-8"
                >
                    {action}
                </ActionButton>
            </div>
        </PopUp>
    );
};

function Variants() {
    const [open, setOpen] = useState();
    const { currentLang } = useStateContext();
    const { variants, createVariant, updateVariant, deleteVariant } =
        useVariants();
    const columns = {
        name: {
            title: translator.tables.categories.name[currentLang],
            render: (item) => (<Link to={`/variant-values/${item.id}`}>{item.name}</Link>)
        },
    };
    return (
        <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
            <header className="flex justify-between items-center">
                <Header
                    category={translator.shared.paper[currentLang]}
                    title={translator.pages.variants.name[currentLang]}
                />
                <div className="flex gap-2">
                    <LinkButton onClick={() => setOpen(true)} to="create/">
                        {translator.pages.variants.linkBtn[currentLang]}
                    </LinkButton>
                </div>
            </header>
            {open && (
                <VariantForm
                    action={translator.shared.add[currentLang]}
                    data={{
                        name: "",
                        parentID: "",
                    }}
                    onSubmit={(data) => createVariant(data)}
                    setOpen={() => setOpen(null)}
                />
            )}

            <Table
                columns={columns}
                data={variants}
                handleEdit={updateVariant}
                handleDelete={deleteVariant}
                options
                Form={VariantForm}
            >
                <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
                    <p className="font-bold text-gray-600">
                        {translator.tables.categories.null[currentLang]}
                    </p>
                </div>
            </Table>
        </div>
    );
}
export default Variants;
