import { useState, useEffect } from "react";
import { useGET } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";

export const useCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const { handleNotification } = useStateContext();

  useEffect(() => {
    useGET("vendor/customers", {}).then(
      (res) => {
        if (res.type == "success") setCustomers(res.data);
        if (res.type == "error") handleNotification(res);
      }
    );
  }, []);

  return { customers };
};
