import { useEffect, useState } from "react";
import { useDELETE, useGET, usePOST, useUPDATE } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";

export const useGetCategories = () => {
  const [categories, setCategories] = useState([]);
  const { handleNotification } = useStateContext();
  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    useGET(`v2/categories/`, {}).then(
      (res) => {
        setCategories(res);
      }
    );
  }, [refresh]);

  const createCategory = (data) => {
    setLoading(true);
    usePOST("v2/categories/", {
      data: data,
    }).then((res) => {
      setLoading(false);
      if (res?.id) {
        handleNotification({ type: 'success', message: 'Category created' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong' })
      }
    });
  };
  const updateCategory = (data) => {
    setLoading(true);
    useUPDATE(`v2/categories/${data.id}/`, {
      data: data,
    }).then((res) => {
      setLoading(false);
      if (res?.id) {
        handleNotification({ type: 'success', message: 'Category updated' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong' });
      }
    });
  };

  const deleteCategory = (id) => {
    setLoading(true);
    useDELETE(`v2/categories/${id}/`, {
      data: { id: id },
    }).then((res) => {
      setLoading(false);
      if (res.type == "error") {
        handleNotification({ type: 'success', message: 'Category deleted' });
        setRefresh(!refresh);
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong' });
      }
    });
  };

  return { categories, createCategory, updateCategory, deleteCategory };
};

