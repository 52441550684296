import { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useDELETE, useGET, usePOST, useUPDATE } from "./utils";

export const useCoupons = () => {
  const { handleNotification } = useStateContext();
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState();
  useEffect(() => {
    setLoading(true);
    useGET("vendor/coupons/", {}).then(
      (res) => {
        if (res.type == "success") setCoupons(res.data);
        if (res.type == "error") handleNotification(res);
      }
    );
    setLoading(false);
  }, [refresh]);

  const createCoupon = (data) => {
    setLoading(true);
    usePOST("vendor/coupons/", {
      data: data,
    }).then((res) => {
      handleNotification(res);
      setLoading(false);
      if (res.type == "success") {
        setRefresh(!refresh);
      }
    });
  };
  const updateCoupon = (data) => {
    setLoading(true);
    useUPDATE("vendor/coupons/", {
      data: data,
    }).then((res) => {
      handleNotification(res);
      setLoading(false);
      if (res.type == "success") {
        setRefresh(!refresh);
      }
    });
  };

  const deleteCoupon = (id) => {
    setLoading(true);
    useDELETE("vendor/coupons", {
      data: { id: id },
    }).then((res) => {
      handleNotification(res);
      setLoading(false);
      if (res.type == "success") {
        setRefresh(!refresh);
      }
    });
  };

  return { coupons, createCoupon, updateCoupon, deleteCoupon };
};
