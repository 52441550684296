import { useState } from "react";
import { Header, Table, PopUp } from "../components";
import { PlusIcon } from "../components/Icons";
import { Input, SelectInput } from "../components/Input";
import { ActionButton } from "../components/Button";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";
import { useVariants, useVariantValues } from "../api/product";
import { useParams } from "react-router-dom";

export const LinkButton = ({ children, onClick }) => {
    return (
        <button
            onClick={() => onClick()}
            className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
        >
            <PlusIcon />
            <span className="font-medium ml-1 mr-2 dark:text-white">{children}</span>
        </button>
    );
};

const VariantValueForm = ({ setOpen, onSubmit, data, action, variants }) => {
    const { handleNotification, currentLang } = useStateContext();
    const [form, setForm] = useState({
        id: data?.id,
        value: data?.value,
        attribute: data?.attribute,
    });
    const handleSubmit = () => {
        if (!form.value || !form.attribute) {
            handleNotification({ type: "error", message: "Complete missing data" });
            return;
        }
        onSubmit(form);
        setOpen();
    };
    return (
        <PopUp
            title={`${action} ${translator.forms.variantsValue.name[currentLang]}`}
            onClose={() => setOpen()}
        >
            <div className="px-6">
                <Input
                    value={form.value}
                    onChange={(e) => setForm({ ...form, value: e.target.value })}
                    label={translator.pages.variantValues.name[currentLang]}
                    type="text"
                    className="w-[250px] mb-4"
                />
            </div>
            <div className="w-full">
                <ActionButton
                    onClick={() => handleSubmit()}
                    className="float-right mt-4 mr-4 px-8"
                >
                    {action}
                </ActionButton>
            </div>
        </PopUp>
    );
};



function VariantValues() {
    const { id } = useParams();
    const [open, setOpen] = useState();
    const { currentLang } = useStateContext();
    const { variantValues, createVariantValue, updateVariantValue, deleteVariantValue } =
        useVariantValues(id);
    const { variants } = useVariants();
    const columns = {
        value: {
            title: translator.pages.variantValues.name[currentLang],
        },
    };
    const Form = (props) => <VariantValueForm {...props} variants={variants} />
    return (
        <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
            <header className="flex justify-between items-center">
                <Header
                    category={translator.shared.paper[currentLang]}
                    title={translator.pages.variantValues.name[currentLang]}
                />
                <div className="flex gap-2">

                    <LinkButton onClick={() => setOpen(true)} to="create/">
                        {translator.forms.variantsValue.name[currentLang]}
                    </LinkButton>
                </div>
            </header>
            {open && (
                <VariantValueForm
                    action={translator.shared.add[currentLang]}
                    data={{
                        value: "",
                        attribute: id,
                    }} variants={variants}
                    onSubmit={(data) => createVariantValue(data)}
                    setOpen={() => setOpen(null)}
                />
            )}
            <Table
                columns={columns}
                data={variantValues}
                handleEdit={updateVariantValue}
                handleDelete={deleteVariantValue}
                options
                Form={Form}
            >
                <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
                    <p className="font-bold text-gray-600">
                        {translator.tables.categories.null[currentLang]}
                    </p>
                </div>
            </Table>
        </div>
    );
}
export default VariantValues;
