import { useState } from "react";
import { usePOST } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";

export const usePackaging = (id) => {
  const { handleNotification } = useStateContext();
  const [loading, setLoading] = useState();

  const togglePackaging = (data) => {
    usePOST('v2/attribute-values/', {
      data: data,
    }).then((res) => {
      setLoading(false);
      if (res?.id) {
        handleNotification({ type: 'success', message: 'variant value created' });
      } else {
        handleNotification({ type: 'error', message: 'Something went wrong try later' });

      }
    });
  };

  return { togglePackaging };
};

